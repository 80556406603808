import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section
          className='pb-24'
          style={{
            background:
              'linear-gradient(131deg, #E8E5FE 26.82%, #FFEED8 96.89%)',
          }}
        >
          <nav className='relative px-10 md:px-12 py-7 lg:py-4'>
            <div className='flex items-center justify-between'>
              <a
                className='navbar-burger flex items-center order-1 lg:order-none gap-10'
                href='#'
              >
                <span className='hidden lg:block text-lg font-semibold'>
                  Dwell.day
                </span>
              </a>
              <a
                className='lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-y-1/2 lg:-translate-x-1/2 pr-4 lg:pr-0'
                href='#'
              ></a>
              <div className='hidden lg:flex items-center gap-5'>
                <a
                  className='inline-flex justify-center items-center text-center h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                  href='https://forms.gle/ZG4Wj74mfje2KUxs9'
                >
                  Sign up
                </a>
              </div>
            </div>
          </nav>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-xs z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-black opacity-20' />
            <nav className='relative p-8 w-full h-full bg-white overflow-y-auto'>
              <div className='flex flex-col justify-between h-full'>
                <div className='flex items-center justify-between mb-16'>
                  <a className='pr-4' href='#'>
                    <img
                      className='h-10'
                      src='mirga-assets/images/logos/mirga-dark-dark.svg'
                      alt=''
                    />
                  </a>
                  <a className='navbar-close' href='#'>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6 18L18 6M6 6L18 18'
                        stroke='#252E4A'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
                <div className='flex flex-col items-center gap-2'>
                  <a
                    className='inline-flex justify-center items-center text-center w-full h-16 p-5 font-semibold tracking-tight text-lg focus:text-neutral-900 bg-transparent hover:bg-transparent focus:bg-transparent border border-transparent rounded-lg focus:ring-4 focus:ring-transparent transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='inline-flex justify-center items-center text-center w-full h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                    href='#'
                  >
                    Sign up free
                  </a>
                </div>
              </div>
            </nav>
          </div>
          <div className='container mx-auto px-4'>
            <div className='relative mt-32 mb-20 lg:mt-64 lg:mb-40'>
              <h1 className='font-heading tracking-tight text-7xl sm:text-8xl md:text-10xl xl:text-12xl 2xl:text-15xl text-center font-semibold'>
                Dwell.day
              </h1>
              <div className='hidden sm:block absolute -top-6 lg:-top-8 xl:-top-12 -left-2 sm:left-12 md:left-16 lg:left-32 xl:left-40 2xl:left-16 3xl:left-40'>
                <h6 className='font-heading md:text-xl lg:text-2xl font-semibold tracking-tight w-44 md:w-48 lg:w-72'>
                  Don't just do something, sit there
                </h6>
              </div>
              <div className='hidden sm:block absolute -bottom-12 lg:-bottom-16 xl:-bottom-18 right-2 md:right-8 lg:right-20 2xl:right-24 3xl:right-48'>
                <h6 className='font-heading md:text-xl lg:text-2xl font-semibold w-32 md:w-48 text-right tracking-tight'>
                  and be
                </h6>
              </div>
              <div className='absolute left-1/2 transform -translate-x-1/2 sm:transform-none sm:left-auto -top-16 sm:-top-4 sm:right-12 md:right-16 lg:right-48 xl:right-48 2xl:top-2 2xl:right-16 3xl:right-48'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={48}
                  height={48}
                  viewBox='0 0 48 48'
                  fill='none'
                >
                  <path
                    d='M48 24C48 19.2532 46.5924 14.6131 43.9553 10.6663C41.3181 6.71953 37.5698 3.64339 33.1844 1.82689C28.799 0.010388 23.9734 -0.464892 19.3178 0.461153C14.6623 1.3872 10.3859 3.67298 7.02944 7.02944C3.67298 10.3859 1.3872 14.6623 0.461153 19.3178C-0.464892 23.9734 0.0103881 28.799 1.82689 33.1844C3.64339 37.5698 6.71953 41.3181 10.6663 43.9553C14.6131 46.5924 19.2532 48 24 48V24H48Z'
                    fill='#FF8C68'
                  />
                  <circle cx={24} cy={24} r={19} fill='#19191B' />
                  <line
                    x1='30.4727'
                    y1='13.1763'
                    x2='18.9422'
                    y2='24.7068'
                    stroke='white'
                    strokeWidth={2}
                  />
                  <line
                    x1={30}
                    y1={25}
                    x2={18}
                    y2={25}
                    stroke='white'
                    strokeWidth={2}
                  />
                  <line
                    x1='30.4727'
                    y1='24.7071'
                    x2='18.9422'
                    y2='36.2376'
                    stroke='white'
                    strokeWidth={2}
                  />
                </svg>
              </div>
            </div>
            <div className='flex flex-wrap justify-center -mx-4'>
              <div className='w-full sm:w-1/2 lg:w-3/12 p-4'>
                <div className='relative h-48 sm:h-full'>
                  <img
                    className='absolute inset-0 w-full h-full object-cover rounded-3xl'
                    src='mirga-assets/images/hero/background4.jpg'
                    alt=''
                  />
                  <div className='absolute left-3 right-3 bottom-5'>
                    <div className='flex items-center justify-between flex-wrap gap-4'></div>
                  </div>
                </div>
              </div>
              <div className='w-full sm:w-1/2 lg:w-1/2 xl:w-5/12 2xl:w-4/12 p-4'></div>
              <div className='w-full lg:w-3/12 p-4'></div>
            </div>
            <div className='flex flex-wrap justify-center -mx-4'>
              <div className='w-full sm:w-1/2 lg:w-1/3 xl:max-w-sm p-4'></div>
              <div className='w-full lg:w-1/3 xl:flex-1 p-4'></div>
            </div>
          </div>
        </section>
        <section className='bg-neutral-50 p-5 sm:p-10' id="product">
          <div className='rounded-3xl bg-white py-32'>
            <div className='container mx-auto px-4'>
              <div className='flex flex-col justify-center items-center h-full'>
                <h4 className='text-center text-6xl sm:text-8xl font-semibold mb-6 font-heading'>
                  AI Journaling App
                </h4>
                <p className='text-center text-neutral-600 text-lg font-medium mb-12 tracking-tight'>
                  Your journey to wellbeing starts here.
                </p>
                <a
                  className='inline-flex justify-center items-center text-center h-20 p-5 font-semibold tracking-tight text-2xl text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                  href='https://forms.gle/ZG4Wj74mfje2KUxs9'
                >
                  Start using Dwell
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 md:py-24 bg-neutral-900' id="contact">
          <div className='container mx-auto px-4'>
            <h2 className='flex flex-wrap mb-2 pb-14 text-6xl sm:text-9xl xl:text-11xl text-white font-semibold border-b border-neutral-600 font-heading'>
              <span className='mr-4'>Contact us</span>
              <svg
                width={30}
                height={31}
                viewBox='0 0 30 31'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15.3529 7.38071C14.3388 6.36424 12.6946 6.36424 11.6805 7.38071C10.6663 8.39718 10.6663 10.0452 11.6805 11.0617L15.812 15.2028C16.8261 16.2192 18.4704 16.2192 19.4845 15.2028C20.4986 14.1863 20.4986 12.5383 19.4845 11.5218L15.3529 7.38071Z'
                  fill='#19191B'
                />
                <path
                  d='M13.4047 14.3978C12.3906 13.3813 10.7464 13.3813 9.73223 14.3978C8.71811 15.4143 8.71811 17.0623 9.73223 18.0788L13.8638 22.2199C14.8779 23.2363 16.5221 23.2363 17.5362 22.2199C18.5504 21.2034 18.5504 19.5554 17.5362 18.5389L13.4047 14.3978Z'
                  fill='#19191B'
                />
              </svg>
            </h2>
            <h4 className='mb-8 text-4xl text-white font-semibold tracking-tight font-heading'>
              Do you have any question?
            </h4>
            <div className='flex flex-wrap -m-2'>
              <div className='w-full p-2'>
                <div className='h-full py-10 px-12 bg-white rounded-4xl'>
                  <div className='flex flex-wrap justify-between items-center -m-4'>
                    <div className='w-auto p-4'>
                      <h4 className='text-4xl font-medium tracking-tight font-heading'>
                        Office in San Francisco, CA
                      </h4>
                    </div>
                    <div className='w-auto p-4'>
                      <div className='flex flex-wrap items-center justify-between -m-5'>
                        <div className='w-auto p-5'>
                          <p className='text-xl text-neutral-600 font-semibold tracking-tight'>
                            1929 Market Street
                          </p>
                        </div>
                        <div className='w-auto p-5' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-2/3 p-2'>
                <div className='h-full p-10 bg-white rounded-4xl'>
                  <div className='flex flex-col justify-between h-full'>
                    <div className='w-full'>
                      <div className='max-w-lg'>
                        <h4 className='mb-2 text-4xl font-medium tracking-tight font-heading'>
                          Contact
                        </h4>
                        <p className='mb-12 text-lg text-neutral-600 font-medium tracking-tight'>
                          Please reach out if you have questions!
                        </p>
                      </div>
                      <ul className='mb-10'>
                        <li className='mb-6 flex'>
                          <div className='flex items-center justify-center w-6 h-6 mr-4'>
                            <svg
                              width={16}
                              height={24}
                              viewBox='0 0 16 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11 24H5C2.243 24 0 21.757 0 19V5C0 2.243 2.243 0 5 0H11C13.757 0 16 2.243 16 5V19C16 21.757 13.757 24 11 24ZM5 2C3.346 2 2 3.346 2 5V19C2 20.654 3.346 22 5 22H11C12.654 22 14 20.654 14 19V5C14 3.346 12.654 2 11 2H5ZM10 19C10 18.448 9.552 18 9 18H7C6.448 18 6 18.448 6 19C6 19.552 6.448 20 7 20H9C9.552 20 10 19.552 10 19Z'
                                fill='#19191B'
                              />
                            </svg>
                          </div>
                          <span className='text-xl font-medium tracking-tight'>
                            +1 7348347849
                          </span>
                        </li>
                        <li className='flex'>
                          <div className='flex items-center justify-center w-6 h-6 mr-4'>
                            <svg
                              width={24}
                              height={22}
                              viewBox='0 0 24 22'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M19 0H5C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 17C0.00158786 18.3256 0.528882 19.5964 1.46622 20.5338C2.40356 21.4711 3.67441 21.9984 5 22H19C20.3256 21.9984 21.5964 21.4711 22.5338 20.5338C23.4711 19.5964 23.9984 18.3256 24 17V5C23.9984 3.67441 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0ZM5 2H19C19.5988 2.00118 20.1835 2.18151 20.679 2.5178C21.1744 2.85409 21.5579 3.33095 21.78 3.887L14.122 11.546C13.5584 12.1073 12.7954 12.4225 12 12.4225C11.2046 12.4225 10.4416 12.1073 9.878 11.546L2.22 3.887C2.44215 3.33095 2.82561 2.85409 3.32105 2.5178C3.81648 2.18151 4.40121 2.00118 5 2ZM19 20H5C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7956 2 17V6.5L8.464 12.96C9.40263 13.8963 10.6743 14.422 12 14.422C13.3257 14.422 14.5974 13.8963 15.536 12.96L22 6.5V17C22 17.7956 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7956 20 19 20Z'
                                fill='#19191B'
                              />
                            </svg>
                          </div>
                          <span className='text-xl font-medium tracking-tight'>
                            hackgoofer@gmail.com
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className='w-full'></div>
                  </div>
                </div>
              </div>
              <div className='w-full md:flex-1 p-2'>
                <div className='h-full p-10 bg-white rounded-4xl'>
                  <div className='flex flex-col justify-between h-full'>
                    <div className='mb-8 w-full'>
                      <img
                        className='mx-auto h-56 object-cover'
                        src='mirga-assets/images/contact/woman.png'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="py-24">
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-4 mb-10 justify-between">
            <div class="w-full md:w-1/5 px-4 mb-6 md:mb-0">
              <a class="inline-block" href="#">
                <img className='h-10' src="assets/logo.png" alt=""/>
              </a>
            </div>
            <div class="w-full md:w-3/5 px-4 mb-6 md:mb-0">
              <ul class="md:flex items-center justify-center">
                <li class="mb-6 md:mb-0 md:mr-12"><a class="inline-block font-bold text-sm hover:text-gray-900" href="#">Hello</a></li>
                <li class="mb-6 md:mb-0 md:mr-12"><a class="inline-block font-bold text-sm hover:text-gray-900" href="#product">Product</a></li>
                <li class="mb-6 md:mb-0 md:mr-12"><a class="inline-block font-bold text-sm hover:text-gray-900" href="#contact">Contact Us</a></li>
              </ul>
            </div>
          </div>
          <p class="text-sm text-gray-500 text-center">© Harmona. 2024 All right reserved.</p>
        </div>
      </section>
      </>
    </React.Fragment>
  );
}

